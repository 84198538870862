<template>
  <div class="loginConiter">
    <div class="loginlabe">
      <div class="lotitle">
        <div class="first">欢迎登录</div>
        <div class="secong">您好！欢迎来到江苏省民用建筑能效测评标识管理系统</div>
      </div>
      <div class="shuru">
        <div class="zhanghu">
          <el-input placeholder="请输入账户" @keyup.native.enter="user_login" v-model="username" clearable>
          </el-input>
        </div>
        <div class="mima">
          <el-input placeholder="请输入密码" v-model="password" show-password @keyup.native.enter="user_login"></el-input>
        </div>
        <div class="mima">
          <el-input v-model="imageCode" placeholder="请输入图形验证码" @keyup.native.enter="user_login">
            <template slot="append">
              <div class="login-code" @click="refreshCode">
                <canvas id="canvas" height="74" width="130"></canvas>
              </div>
            </template>
          </el-input>
        </div>
        <!-- <div class="logcont">
          <div class="forget">忘记密码</div> -->
        <!-- <div class="forget">申请注册</div> -->
        <!-- </div> -->
      </div>
      <div class="commit">
        <div @click="user_login" class="logincomit">登录</div>
        <div class="cont">点击登录即代表同意《服务协议》</div>
      </div>
    </div>
  </div>
</template>
<script>
import { login } from "../api/user";

export default {
  data() {
    return {
      username: '',
      password: '',
      imageCode: '',
      verifyCode: '',
    }
  },
  mounted() {
    this.verifyCode = this.createCanvas();
  },
  methods: {
    user_login() {
      if (this.imageCode != this.verifyCode) {
        this.$message.error('验证码错误!');
        return;
      }
      let that = this;
      if (this.username && this.username != '' && this.password && this.password != '') {
        let params = {
          username: this.username,
          password: this.password,
        }
        login(params).then(res => {
          console.log(res);
          if (res.data.type && (res.data.type == 1 || res.data.type == 2 || res.data.type == 3 || res.data.type == 4)) {
            let user = res.data;
            user.username = that.username;
            that.$store.commit('set_user', user);
            that.$router.push({
              path: '/'
            })
          } else {
            that.$message.warning('普通用户暂不支持登录');
          }

        })
      } else {
        this.$message.warning('请填写正确的用户名和密码')
      }

    },
    refreshCode() {
      this.verifyCode = this.createCanvas();
    },
    rn(min, max) {
      return parseInt((Math.random() * (max - min) + min).toString());
    },
    rc(min, max) {
      let r = this.rn(min, max);
      let g = this.rn(min, max);
      let b = this.rn(min, max);
      return `rgb(${r},${g},${b})`;
    },
    createCanvas() {
      let ctx = document.getElementById('canvas').getContext('2d');
      ctx.fillStyle = this.rc(180, 230);
      ctx.fillRect(0, 0, 130, 74);
      let pool = '0123456789';
      let code = '';
      for (let i = 0; i < 4; i++) {
        let c = pool[this.rn(0, pool.length)];
        let fs = this.rn(24, 30);
        let deg = this.rn(-30, 30);
        ctx.font = fs + 'px sans-serif';
        ctx.textBaseline = 'top';
        ctx.fillStyle = this.rc(0, 200);
        ctx.save();
        ctx.translate(30 * i + 15, this.rn(24, 40));
        ctx.rotate((deg * Math.PI) / 180);
        ctx.fillText(c, 0, 0);
        ctx.restore();
        code += c;
      }
      for (let i = 0; i < 5; i++) {
        ctx.beginPath();
        ctx.moveTo(this.rn(0, 130), this.rn(0, 74));
        ctx.lineTo(this.rn(0, 130), this.rn(0, 74));
        ctx.strokeStyle = this.rc(0, 160);
        ctx.closePath();
        ctx.stroke();
      }
      for (let i = 0; i < 40; i++) {
        ctx.beginPath();
        ctx.arc(this.rn(0, 130), this.rn(0, 74), 1, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fillStyle = this.rc(0, 200);
        ctx.fill();
      }
      return code;
    }
  }
};
</script>
<style scoped>
/deep/.el-input--suffix .el-input__inner {
  width: 574px;
  height: 74px;
  font-size: 20px;
}

/deep/.el-input-group>.el-input__inner {
  /* width: 574px; */
  height: 74px;
  font-size: 20px;
}
/deep/.el-input-group__append {
  /* width: 574px; */
  padding: 0 !important;
}
</style>
<style scoped lang="scss">
.loginConiter {
  background: url('http://cloud.cdmp.tech/279dc4f0c0b6e2f1dca11b0449745539.jpg') no-repeat 0 0;
  width: 100%;
  height: 100vh;
  background-size: 100% 100%;
}

.login-code{
  height: 74px;
}
.loginlabe {
  width: 30%;
  height: 100%;
  position: fixed;
  padding-left: 255px;
  padding-right: 105px;
  right: 0;

  .lotitle {
    margin-top: 210px;

    .first {
      font-size: 42px;
      font-weight: bold;
      color: #313131;
    }

    .secong {
      margin-top: 18px;
      color: #979797;
      font-size: 20px;
    }
  }

  .shuru {
    margin-top: 50px;

    .mima {
      margin-top: 32px;
    }
  }

  .logcont {
    margin-top: 27px;
    width: 574px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .forget {
      font-size: 20px;
      color: #8C8C8C;
      cursor: pointer;
    }
  }

  .commit {
    margin-top: 57px;

    .logincomit {
      width: 574px;
      height: 76px;
      background: #0065E1;
      border-radius: 38px;
      color: #ffffff;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      line-height: 76px;
      cursor: pointer;
    }

    .cont {
      width: 574px;
      margin-top: 24px;
      font-size: 20px;
      color: #979797;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
